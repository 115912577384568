import { postRequest } from 'utils/rest-api-util';

export interface UserSignInData {
  email: string;
  password: string;
}

export type UserSignInResponse = {
  user: {
    name: string;
    token: string;
  };
};

export const postUserSignIn = async (
  userParams: UserSignInData,
): Promise<Response> => {
  return await postRequest(`/api/v1/login`, userParams);
};
